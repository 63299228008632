import React, { useContext, useState } from "react";
import "../../assets/style/globalStyle.scss";
import {
  updateSponsorById,
  deleteSponsor,
} from "../../services/sponsorService";

import {
  GlobalStateContext,
  GlobalDispatchContext,
} from "../../context/GlobalContextProvider";
import ModalCard from "../../components/modalCard";

import ImagePicker from "../../components/imagePicker";

const UpdateSponsorPage = () => {
  const GoToModal = async ({ modalType, showModal }) => {
    dispatch({
      type: "show_modal",
      payload: showModal,
      modalType: modalType,
    });
  };

  const dispatch = useContext(GlobalDispatchContext);
  const state = useContext(GlobalStateContext);

  const [updatedName, setUpdatedName] = useState("");

  if (!state) {
    return (
      <div className="container is-fullhd" style={{ width: "880px" }}>
        <h1>Pas de sponsor </h1>
      </div>
    );
  }

  const { _id, logo, name } = state.sponsor;

  return (
    <div className="box">
      <div className="field">
        <label className="label">
          Nom du sponsor
          <input
            className="input"
            type="text"
            placeholder={name}
            value={updatedName}
            onChange={(e) => {
              setUpdatedName(e.target.value);
            }}
          />
        </label>
      </div>
      <br/>

      <div className="field">
        <p className="label">
          Logo
          <ImagePicker
            selectedId={_id}
            imgLink={
              logo
                ? `https://res.cloudinary.com/ball-n-connect/image/v1/upload/sponsor_image/${_id}.jpg`
                : null
            }
            type="sponsor"
          />
        </p>
      </div>
      <br/>

      <div className="columns">
        <div className="column">
          <button
            className="button is-primary is-outlined"
            onClick={() =>
              updateSponsorById({
                sponsorId: _id,
                logo: logo,
                name: updatedName === "" ? name : updatedName,
              })
            }
          >
            Enregistrer les modifications
          </button>
        </div>

        <div className="column">
          <button
            className="button is-danger is-outlined"
            onClick={() => GoToModal({ modalType: "delete", showModal: true })}
          >
            Supprimer la société
          </button>
        </div>
      </div>

      <ModalCard
        isActiveClassName={state.showModal && state.modalType === "delete"}
        title="Supprimer"
        content="Voulez-vous supprimer ce sponsor ?"
        validateClick={() => {
          deleteSponsor({ sponsorId: _id });
          dispatch({
            type: "show_modal",
            payload: false,
            modalType: "",
          });
        }}
      />
    </div>
  );
};

export default UpdateSponsorPage;
