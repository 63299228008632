import React from "react";
import { Router } from "@reach/router";
import Layout from "../components/layout";
import PrivateRoute from "../components/privateRoute";
import RedirectLink from "../components/redirectLink";
import Profile from "../components/profile";
import Login from "./login";
import AllEvents from "./event/allEvents";
import UpdateEventPage from "./event/updateEventPage";
import CreateEventPage from "./event/createEventPage";
import AllPlaygrounds from "./playground/allPlaygrounds";
import CreatePlaygroundPage from "./playground/createPlaygroundPage";
import AllUserRequests from "./userRequest/allUserRequests"
import AnswerUserRequestPage from "./userRequest/answerUserRequestPage";
import AuthorCount from "./authorCount";
import AllUserEmail from "./allUserEmail";
import UpdatePlaygroundPage from "./playground/updatePlaygroundPage";
import AdminPage from "./adminPage";
import AllSocieties from "./society/allSocieties";
import AllPartners from "./partner/allPartners";
import CreatePartnerPage from "./partner/createPartnerPage";
import UpdateSocietyPage from "./society/updateSocietyPage";
import CreateSocietyPage from "./society/createSocietyPage";
import AllSponsors from "./sponsor/allSponsors";
import UpdateSponsorPage from "./sponsor/updateSponsorPage";
import CreateSponsorPage from "./sponsor/createSponsorPage";
import AllTeams from "./team/allTeams";
import UpdateTeamPage from "./team/updateTeamPage";
import CreateTeamPage from "./team/createTeamPage";
import UpdatePartnerPage from "./partner/updatePartnerPage";
import CreateArticlePage from "./article/createArticlePage";
import UpdateArticlePage from "./article/updateArticlePage";
import AllArticles from "./article/allArticles";

//Pages pour le site web
import EventPage from "./eventPage";
import BlogPage from "../templates/blog-list";
import BnCoachingPage from "./bnCoachingPage";
// import PresentationPage from "./site/presentationPage";
import wikiPage from "./wikiPage";
import MapPlayground from "./mapPlayground";
import privacyPage from "./privacyPage";

const App = () => (
  <Layout>
    <Router basepath="app">
      <RedirectLink path="/redirect" />
      <PrivateRoute path="/profile" component={Profile} />
      <PrivateRoute path="/authorCount" component={AuthorCount} />
      <PrivateRoute path="/allUserEmail" component={AllUserEmail} />
      <PrivateRoute path="/allPlaygrounds" component={AllPlaygrounds} />
      <PrivateRoute
        path="/createPlaygroundPage"
        component={CreatePlaygroundPage}
      />
      <PrivateRoute
        path="/updatePlaygroundPage"
        component={UpdatePlaygroundPage}
      />
      <PrivateRoute path="/allUserRequests" component={AllUserRequests} />
      <PrivateRoute
        path="/answerUserRequestPage"
        component={AnswerUserRequestPage}
      />
      <PrivateRoute path="/adminPage" component={AdminPage} />
      <PrivateRoute path="/allEvents" component={AllEvents} />
      <PrivateRoute path="/updateEventPage" component={UpdateEventPage} />
      <PrivateRoute path="/createEventPage" component={CreateEventPage} />
      <PrivateRoute path="/allSocieties" component={AllSocieties} />
      <PrivateRoute path="/updateSocietyPage" component={UpdateSocietyPage} />
      <PrivateRoute path="/createSocietyPage" component={CreateSocietyPage} />
      <PrivateRoute path="/allPartners" component={AllPartners} />
      <PrivateRoute path="/createPartnerPage" component={CreatePartnerPage} />
      <PrivateRoute path="/updatePartnerPage" component={UpdatePartnerPage} />
      <PrivateRoute path="/allSponsors" component={AllSponsors} />
      <PrivateRoute path="/updateSponsorPage" component={UpdateSponsorPage} />
      <PrivateRoute path="/createSponsorPage" component={CreateSponsorPage} />
      <PrivateRoute path="/allTeams" component={AllTeams} />
      <PrivateRoute path="/updateTeamPage" component={UpdateTeamPage} />
      <PrivateRoute path="/createTeamPage" component={CreateTeamPage} />
      <PrivateRoute path="/createArticlePage" component={CreateArticlePage} />
      <PrivateRoute path="/updateArticlePage" component={UpdateArticlePage} />
      <PrivateRoute path="/allArticles" component={AllArticles} />
      <PrivateRoute path="/mapPlayground" component={MapPlayground} />
      <PrivateRoute path="/eventPage" component={EventPage} />
      <PrivateRoute path="/blog" component={BlogPage} />
      <PrivateRoute path="/bnCoachingPage" component={BnCoachingPage} />
      <PrivateRoute path="/wikiPage" component={wikiPage} />
      <PrivateRoute path="/privacyPage" component={privacyPage} />

      <Login path="/login" />
    </Router>
  </Layout>
);

export default App;
