import React, { useState } from "react";
import { Link, navigate } from "gatsby";
import styled from "styled-components";
import "../assets/style/globalStyle.scss";
import bnclogo from "../assets/img/bnc-logo.png";
import { getUser, isLoggedIn, logout } from "../services/authService";

const NavLink = styled(Link)`
  text-decoration: none;
  color: #000;
`;

const NavBar = () => {
  const [isActive, setisActive] = useState(false);

  let greetingMessage = "";
  if (isLoggedIn()) {
    greetingMessage = `Bienvenue ${getUser().name}`;
  } else {
    greetingMessage = "Vous n'êtes pas connecté";
  }

  return (
    <nav className="navbar is-fixed-top has-shadow pr-5">
      {isLoggedIn() ? (
        <>
          <div className="navbar-brand">
            <a className="navbar-item" href="/">
              <img src={bnclogo} width="32" height="30" alt="Placeholder img" />
            </a>

            <a
              role="button"
              // href=""
              className={`navbar-burger burger ${isActive ? "is-active" : ""}`}
              data-target="navMenu"
              aria-label="menu"
              aria-expanded="false"
              onClick={() => setisActive(!isActive)}
              tabIndex={0}
              onKeyDown={() => setisActive(!isActive)}
            >
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
            </a>
          </div>
          <div className="navbar-item ">
            <span className="is-hidden-mobile">{greetingMessage}</span>
          </div>

          <div
            className={`navbar-menu ${isActive ? "is-active" : ""}`}
            id="navMenu"
          >
            <div className="navbar-end has-text-black is-hidden-desktop">
              <div className="navbar-item">
                <NavLink to="/">Home</NavLink>
              </div>
              <div className="navbar-item">
                <NavLink to="/app/profile">Profile</NavLink>
              </div>
              <div className="navbar-item">
                {isLoggedIn() ? (
                  <NavLink
                    to="/"
                    onClick={(event) => {
                      event.preventDefault();
                      navigate(`/app/authorCount`);
                    }}
                  >
                    CountAuthor
                  </NavLink>
                ) : null}
              </div>
              <div className="navbar-item">
                {isLoggedIn() ? (
                  <NavLink
                    to="/"
                    onClick={(event) => {
                      event.preventDefault();
                      navigate(`/app/createPlaygroundPage`);
                    }}
                  >
                    Playground Form
                  </NavLink>
                ) : null}
              </div>
              <div className="navbar-item">
                {isLoggedIn() ? (
                  <NavLink
                    to="/"
                    onClick={(event) => {
                      event.preventDefault();
                      navigate(`/app/allPlaygrounds`);
                    }}
                  >
                    Playgrounds
                  </NavLink>
                ) : null}
              </div>
              <div className="navbar-item">
                {isLoggedIn() ? (
                  <NavLink
                    to="/"
                    onClick={(event) => {
                      event.preventDefault();
                      navigate(`/app/createEventPage`);
                    }}
                  >
                    Event Form
                  </NavLink>
                ) : null}
              </div>
              <div className="navbar-item">
                {isLoggedIn() ? (
                  <NavLink
                    to="/"
                    onClick={(event) => {
                      event.preventDefault();
                      navigate(`/app/allEvents`);
                    }}
                  >
                    Events
                  </NavLink>
                ) : null}
              </div>
              <div className="navbar-item">
                {isLoggedIn() ? (
                  <NavLink
                    to="/"
                    onClick={(event) => {
                      event.preventDefault();
                      navigate(`/app/createSocietyPage`);
                    }}
                  >
                    Society Form
                  </NavLink>
                ) : null}
              </div>
              <div className="navbar-item">
                {isLoggedIn() ? (
                  <NavLink
                    to="/"
                    onClick={(event) => {
                      event.preventDefault();
                      navigate(`/app/allSocieties`);
                    }}
                  >
                    Societies
                  </NavLink>
                ) : null}
              </div>
              <div className="navbar-item">
                {isLoggedIn() ? (
                  <NavLink
                    to="/"
                    onClick={(event) => {
                      event.preventDefault();
                      navigate(`/app/createSponsorPage`);
                    }}
                  >
                    Sponsor Form
                  </NavLink>
                ) : null}
              </div>
              <div className="navbar-item">
                {isLoggedIn() ? (
                  <NavLink
                    to="/"
                    onClick={(event) => {
                      event.preventDefault();
                      navigate(`/app/allSponsors`);
                    }}
                  >
                    Sponsors
                  </NavLink>
                ) : null}
              </div>
              <div className="navbar-item">
                {isLoggedIn() ? (
                  <NavLink
                    to="/"
                    onClick={(event) => {
                      event.preventDefault();
                      navigate(`/app/createTeamPage`);
                    }}
                  >
                    Team Form
                  </NavLink>
                ) : null}
              </div>
              <div className="navbar-item">
                {isLoggedIn() ? (
                  <NavLink
                    to="/"
                    onClick={(event) => {
                      event.preventDefault();
                      navigate(`/app/allTeams`);
                    }}
                  >
                    Teams
                  </NavLink>
                ) : null}
              </div>
              <div className="navbar-item">
                {isLoggedIn() ? (
                  <NavLink
                    to="/"
                    onClick={(event) => {
                      event.preventDefault();
                      navigate(`/app/allUserRequests`);
                    }}
                  >
                    User Requests
                  </NavLink>
                ) : null}
              </div>
              <div className="navbar-item">
                {isLoggedIn() ? (
                  <NavLink
                    to="/"
                    onClick={(event) => {
                      event.preventDefault();
                      logout(() => navigate(`/app/login`));
                    }}
                  >
                    Logout
                  </NavLink>
                ) : null}
              </div>
            </div>
          </div>
        </>
      ) : null}
    </nav>
  );
};

export default NavBar;
