import React, { useState, useEffect } from "react";
import { navigate } from "gatsby";
import { handleLogin, isLoggedIn } from "../services/authService";
import "../assets/style/globalStyle.scss";

const Login = () => {
  const [email, setEmail] = useState([]);
  const [password, setPassword] = useState([]);

  useEffect(() => {
    if (isLoggedIn()) {
      navigate(`/app/profile`);
    }
  }, []);

  return (
    <section className="hero has-background-white-ter is-fullheight">
      <div className="hero-body">
        <div className="container">
          <div className="columns is-centered">
            <div className="column is-3-desktop is-6-tablet is-12-mobile">
              <div className="box" style={{ height: "300px" }}>
                <h1
                  className="has-text-centered is-size-2"
                  style={{ marginBottom: "14px" }}
                >
                  Log in
                </h1>
                <div className="field">
                  <label>
                    E-mail
                    <input
                      className="input"
                      type="text"
                      name="email"
                      placeholder="Ton adresse mail"
                      style={{ marginBottom: "14px" }}
                      onChange={(e) => {
                        e.persist();
                        setEmail(e.target.value);
                      }}
                    />
                  </label>
                </div>
                <div className="field">
                  <label>
                    Mot de passe
                    <input
                      className="input"
                      type="password"
                      name="password"
                      placeholder="Ton mot de passe"
                      style={{ marginBottom: "14px" }}
                      onChange={(e) => {
                        e.persist();
                        setPassword(e.target.value);
                      }}
                    />
                  </label>
                </div>

                <div className="columns">
                  <div className="column">
                    <button
                      className="button"
                      onClick={() => handleLogin({ email, password })}
                    >
                      se connecter
                    </button>
                  </div>
                  <div className="column">
                    <button className="button" onClick={() => navigate(`/`)}>
                      retour
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Login;
