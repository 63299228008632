import React, { useContext, useState } from "react";
import "../../assets/style/globalStyle.scss";
import {
    updateArticleById,
    deleteArticle,
} from "../../services/articleService";
import {
    GlobalStateContext,
    GlobalDispatchContext,
} from "../../context/GlobalContextProvider";
import ModalCard from "../../components/modalCard";
import ImagePicker from "../../components/imagePicker";

const UpdateArticlePage = () => {
    const GoToModal = async ({ modalType, showModal }) => {
        dispatch({
            type: "show_modal",
            payload: showModal,
            modalType: modalType,
        });
    };

    const dispatch = useContext(GlobalDispatchContext);
    const state = useContext(GlobalStateContext);

    const [updatedTitle, setUpdatedTitle] = useState("");
    const [updatedDescription, setUpdatedDescription] = useState("");
    const [updatedAuthor, setUpdatedAuthor] = useState("");
    const [updatedDate, setUpdatedDate] = useState("");
    const [updatedCategorie, setUpdatedCategorie] = useState("");
    const [updatedLink, setUpdatedLink] = useState("");

    if (!state) {
        return (
            <div className="container is-fullhd" style={{ width: "880px" }}>
                <h1>Pas de article </h1>
            </div>
        );
    }

    const { _id, image, title, description, date, author, categorie, link } = state.article;

    return (
        <div className="box">
            <div className="field">
                <label className="label">
                    Titre de l'article
                    <input
                        className="input"
                        type="text"
                        placeholder={title}
                        value={updatedTitle}
                        onChange={(e) => {
                            setUpdatedTitle(e.target.value);
                        }}
                    />
                </label>
            </div>
            <div className="field">
                <label className="label">
                    Description de l'article
                    <input
                        className="input"
                        type="text"
                        placeholder={description}
                        value={updatedDescription}
                        onChange={(e) => {
                            setUpdatedDescription(e.target.value);
                        }}
                    />
                </label>
            </div>
            <div className="field">
                <label className="label">
                    Autheur de l'article
                    <input
                        className="input"
                        type="text"
                        placeholder={author}
                        value={updatedAuthor}
                        onChange={(e) => {
                            setUpdatedAuthor(e.target.value);
                        }}
                    />
                </label>
            </div>
            <div className="field">
                <label className="label">
                    Date de l'article
                    <p>
                        <i>valeur sélectionnée par l'admin :</i>{" "}
                        <b>
                            {new Intl.DateTimeFormat("fr-FR").format(
                                Date.parse(date)
                            )}
                        </b>
                    </p>
                    <input
                        className="input"
                        type="date"
                        placeholder={date}
                        value={updatedDate}
                        onChange={(e) => {
                            setUpdatedDate(e.target.value);
                        }}
                    />
                </label>
            </div>
            <div className="field">
                <label className="label">
                    Catégorie de l'article
                    <p>
                        <i>valeur sélectionnée par l'admin :</i>{" "}
                        <b>
                            {categorie}
                        </b>
                    </p>
                    <div
                        className="select"
                        value={updatedCategorie}
                        onChange={(e) => setUpdatedCategorie(e.target.value)}
                    >
                        <select>
                            <option>Sélectionner votre catégorie d'article</option>
                            <option value="Equipe">Equipe</option>
                            <option value="Coach">Coach</option>
                            <option value="Application">Application</option>
                            <option value="NBA">NBA</option>
                            <option value="BallNConnect">Ball'N Connect</option>
                            <option value="Evenement">Evenement</option>
                        </select>
                    </div>
                </label>
            </div>
            <div className="field">
                <label className="label">
                    Lien de l'article
                    <input
                        className="input"
                        type="text"
                        placeholder={link}
                        value={updatedLink}
                        onChange={(e) => {
                            setUpdatedLink(e.target.value);
                        }}
                    />
                </label>
            </div>

            <div className="field">
                <p className="label">
                    Logo
                    <ImagePicker
                        selectedId={_id}
                        imgLink={
                            image
                                ? `https://res.cloudinary.com/ball-n-connect/image/v1/upload/article_image/${_id}.jpg`
                                : null
                        }
                        type="article"
                    />
                </p>
            </div>
            <br />

            <div className="columns">
                <div className="column">
                    <button
                        className="button is-primary is-outlined"
                        onClick={() =>
                            updateArticleById({
                                articleId: _id,
                                title: updatedTitle === "" ? title : updatedTitle,
                                description: updatedDescription === "" ? description : updatedDescription,
                                date: updatedDate === "" ? date : updatedDate,
                                author: updatedAuthor === "" ? author : updatedAuthor,
                                categorie: updatedCategorie === "" ? categorie : updatedCategorie,
                                link: updatedLink === "" ? link : updatedLink,
                                image: image,
                            })
                        }
                    >
                        Enregistrer les modifications
                    </button>
                </div>

                <div className="column">
                    <button
                        className="button is-danger is-outlined"
                        onClick={() => GoToModal({ modalType: "delete", showModal: true })}
                    >
                        Supprimer l'article
                    </button>
                </div>
            </div>

            <ModalCard
                isActiveClassName={state.showModal && state.modalType === "delete"}
                title="Supprimer"
                content="Voulez-vous supprimer cet article ?"
                validateClick={() => {
                    deleteArticle({ articleId: _id });
                    dispatch({
                        type: "show_modal",
                        payload: false,
                        modalType: "",
                    });
                }}
            />
        </div>
    );
};

export default UpdateArticlePage;
