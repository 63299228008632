import React, { useContext } from "react";
import "../../assets/style/globalStyle.scss";
import { addSociety } from "../../services/societyService";
import { navigate } from "gatsby";
import { GlobalDispatchContext } from "../../context/GlobalContextProvider";

import { Formik } from "formik";
import * as Yup from "yup";

const CreateSocietySchema = Yup.object().shape({
  name: Yup.string().required("Veuillez entrer le nom de la société"),
  phoneNumber: Yup.string().matches(/^[0-9]+$/, "Caractères invalides"),
  email: Yup.string()
    .email("Email invalide")
    .required("Veuillez entrer un email de contact"),
  contactName: Yup.string().required(
    "Veuillez entrer le nom du contact de la société"
  ),
});

const CreateSocietyPage = () => {
  const dispatch = useContext(GlobalDispatchContext);

  return (
    <div className="box">
      <h3 className="title mt-3">AJOUTER UNE SOCIÉTÉ</h3>
      <h4 className="subtitle">
        Formulaire d'ajout d'une société pour un admin BNC
      </h4>
      <p>* Champs obligatoires</p>

      <br />
      <Formik
        initialValues={{
          name: "",
          url: "",
          phoneNumber: "",
          email: "",
          contactName: "",
          description: "",
        }}
        validationSchema={CreateSocietySchema}
        onSubmit={async (values) => {
          const newSociety = await addSociety({
            logo: "",
            name: values.name,
            url: values.url,
            phoneNumber: values.phoneNumber,
            email: values.email,
            contactName: values.contactName,
            description: values.description,
          });
          dispatch({
            type: "get_society",
            payload: newSociety,
          });
          navigate("/app/updateSocietyPage");
        }}
      >
        {({
          setFieldValue,
          handleChange,
          handleSubmit,
          values,
          errors,
          touched,
        }) => (
          <>
            <div className="columns">
              <div className="column ">
                <div className="field">
                  <label className="label">
                    Nom de la société *
                    <input
                      className="input"
                      type="text"
                      placeholder="Nom de la société"
                      value={values.name}
                      onChange={(e) => setFieldValue("name", e.target.value)}
                    />
                  </label>
                </div>
                {errors.name && touched.name && (
                  <p style={{ color: "#cc0000" }}>{errors.name}</p>
                )}
              </div>
              <div className="column ">
                <div className="field">
                  <label className="label">
                    Nom/prénom du contact *
                    <input
                      className="input"
                      type="text"
                      placeholder="Nom/prénom contact de la société"
                      value={values.contactName}
                      onChange={(e) =>
                        setFieldValue("contactName", e.target.value)
                      }
                    />
                  </label>
                </div>
              </div>
              {errors.contactName && touched.contactName && (
                <p style={{ color: "#cc0000" }}>{errors.contactName}</p>
              )}
            </div>
            <br />

            <div className="columns is-centered">
              <div className="column ">
                <div className="field">
                  <label className="label">
                    URL
                    <input
                      className="input"
                      type="url"
                      placeholder="URL de la société"
                      value={values.url}
                      onChange={(e) => setFieldValue("url", e.target.value)}
                    />
                  </label>
                </div>
              </div>

              <div className="column ">
                <div className="field">
                  <label className="label">
                    Numéro de téléphone
                    <input
                      className="input"
                      type="tel"
                      placeholder="Numéro de téléphone de la société"
                      value={values.phoneNumber}
                      onChange={(e) =>
                        setFieldValue("phoneNumber", e.target.value)
                      }
                    />
                  </label>
                </div>
                {errors.phoneNumber && touched.phoneNumber && (
                  <p style={{ color: "#cc0000" }}>{errors.phoneNumber}</p>
                )}
              </div>
              <br />
              <div className="column ">
                <div className="field">
                  <label className="label">
                    Email *
                    <input
                      className="input"
                      type="email"
                      placeholder="Email de la société"
                      value={values.email}
                      onChange={(e) => setFieldValue("email", e.target.value)}
                    />
                  </label>
                </div>
                {errors.email && touched.email && (
                  <p style={{ color: "#cc0000" }}>{errors.email}</p>
                )}
              </div>
            </div>
            <br />

            <div className="field">
              <label className="label">
                Description
                <textarea
                  className="textarea"
                  placeholder="Description de la société"
                  value={values.description}
                  onChange={(e) => setFieldValue("description", e.target.value)}
                ></textarea>
              </label>
            </div>

            <br />

            <div className="columns is-multiline">
              <div className="column">
                <button
                  className="button is-success is-outlined"
                  type="submit"
                  onClick={() => {
                    handleSubmit();
                  }}
                >
                  Ajout Société
                </button>
              </div>
            </div>
          </>
        )}
      </Formik>
    </div>
  );
};

export default CreateSocietyPage;
