import React from "react";
import { getUser } from "../services/authService";

const Profile = () => (
  <div className="column box">
    <section className="hero is-small">
      <div className="hero-body">
        <div className="container">
          <h1>Your profile</h1>
          <ul>
            <li>Name: {getUser().name}</li>
            <li>E-mail: {getUser().email}</li>
          </ul>
        </div>
      </div>
    </section>
  </div>
);

export default Profile;
