import React, { useEffect, useState } from "react";
import { useLocation } from "@reach/router";

const RedirectLink = () => {
  const [url, setUrl] = useState("");
  const path = new URLSearchParams(useLocation().search).get("p");
  const query = new URLSearchParams(useLocation().search).get("q");

  useEffect(() => {
    if (path) {
      if (query) setUrl(`ballnconnect://redirect/${path}?q=${query}`);
      else setUrl(`ballnconnect://redirect/${path}`);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="column is-10 mt-4 mobile is-hidden-mobile">
        <section className="hero">
          <div className="hero-body">
            <div className="container">
              <h1 className="title">Site de redirection Ball'N Connect</h1>
              <br />
              <h2 className="subtitle">
                Connection via un ordinateur, redirection vers le site, utilisez
                le lien depuis un téléphone pour une redirection vers
                l'application.
              </h2>
              <button>
                <a target="blank" role="button" href="http://ballnconnect.com">
                  Redirection vers le site
                </a>
              </button>
            </div>
          </div>
        </section>
      </div>
      <div className="column is-10-desktop mt-4 p-6 is-hidden-desktop">
        <section className="hero">
          <div className="hero-body">
            <div className="container">
              <h1 className="title">Site de redirection Ball'N Connect</h1>
              <br />
              <h2 className="subtitle">
                Redirect vers l'application, merci de vérifiez que l'application
                soit bien fermée.
              </h2>
              <br />
              <h2 className="subtitle">
                Afin d'être rediriger cliquer sur le lien suivant :
              </h2>
              <button>
                <a target="blank" role="button" href={url}>
                  Redirection vers l'application
                </a>
              </button>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default RedirectLink;
