import React, { useState, useEffect, useContext } from "react";
import "../../assets/style/globalStyle.scss";
import { navigate } from "gatsby";
import { getAllArticles, getArticleById } from "../../services/articleService";
import { GlobalDispatchContext } from "../../context/GlobalContextProvider";
import CardArticle from "../../components/cardArticle";

const AllArticles = () => {
  const dispatch = useContext(GlobalDispatchContext);
  const [articles, setArticles] = useState([]);

  const getAllArticlesToModerate = async () => {
    const data = await getAllArticles();

    if (!data) {
      return (
        <div className="container is-fullhd" style={{ width: "880px" }}>
          <h1>Pas d'évènements en cours</h1>
        </div>
      );
    }

    setArticles(data);
  };

  const GoToArticle = async (id) => {
    const article = await getArticleById(id);
    dispatch({
      type: "get_article",
      payload: article,
    });
    navigate("/app/updateArticlePage");
  };

  useEffect(() => {
    getAllArticlesToModerate();
  }, []);

  return (
    <div className="columns is-multiline">
      {articles.length !== 0 &&
        articles.articles.map((article) => (
          <div key={article.title} className="column is-one-third">
            <CardArticle
              logo={
                article.image
                  ? `https://res.cloudinary.com/ball-n-connect/image/v1/upload/article_image/${article._id}.jpg`
                  : null
              }
              title={article.title}
              description={article.description}
              author={article.author}
              date={article.date}
              onClick={() => GoToArticle(article._id)}
            />
          </div>
        ))}
    </div>
  );
};

export default AllArticles;
