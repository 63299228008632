import React from "react";
import "../assets/style/globalStyle.scss";

const Card = ({
    logo,
    title,
    description,
    author,
    date,
    onClick,
}) => {
    return (
        <div className="card">
            <div className="card-content">
                <div className="media">
                    <div className="media-left">
                        <figure className="image is-48x48">
                            <img src={logo} alt=""></img>
                        </figure>
                    </div>
                    <div className="media-content">
                        <p className="title is-4">{title}</p>
                        Auteur de l'article: {author} <br />
                        Date article:       {new Intl.DateTimeFormat("fr-FR").format(
                            Date.parse(date)
                        )} <br />
                    </div>
                </div>

                <div className="content">
                    {description} <br />
                </div>
                <footer className="card-footer level-right">
                    <button className="button" onClick={onClick}>
                        Voir détails
                    </button>
                </footer>
            </div>
        </div>
    );
};

export default Card;
