import React from "react";
import { Link, navigate } from "gatsby";
import styled from "styled-components";
import "../assets/style/globalStyle.scss";

import { isLoggedIn, logout } from "../services/authService";

const NavLink = styled(Link)`
  text-decoration: none;
  color: #000;
`;

const SideBar = () => {
  return (
    <div className="column is-2 is-hidden-mobile">
      {isLoggedIn() ? (
        <aside
          className="menu is-3-desktop px-4 pt-6"
          style={{
            backgroundColor: "#FFF",
            height: "100vh",
            position: "sticky",
            overflowY: "auto",
            top: "0",
            bottom: "0",
          }}
        >
          <p className="menu-label">General</p>
          <ul className="menu-list">
            <li>
              <NavLink to="/">Site</NavLink>
            </li>
            <li>
              <NavLink to="/app/adminPage">Dashboard</NavLink>
            </li>
            <li>
              <NavLink to="/app/profile">Profile</NavLink>
            </li>
            <li>
              <NavLink to="/app/authorCount">
                Compte des ajouts utilisateurs
              </NavLink>
            </li>
          </ul>
          <p className="menu-label">Administration</p>
          <ul className="menu-list">
            <li>
              <NavLink to="/app/allPlaygrounds">Playgrounds</NavLink>
            </li>
            <li>
              <NavLink to="/app/allPartners">Partenaires</NavLink>
            </li>
            <li>
              <NavLink to="/app/allEvents">Events</NavLink>
            </li>
            <li>
              <NavLink to="/app/allSocieties">Sociétés</NavLink>
            </li>
            <li>
              <NavLink to="/app/allSponsors">Sponsors</NavLink>
            </li>
            <li>
              <NavLink to="/app/allTeams">Teams</NavLink>
            </li>
            <li>
              <NavLink to="/app/allArticles">Articles</NavLink>
            </li>
            <li>
              <NavLink to="/app/allUserRequests">Demandes Utilisateurs</NavLink>
            </li>
          </ul>
          <p className="menu-label">Création</p>
          <ul className="menu-list">
            <li>
              <NavLink to="/app/createPlaygroundPage">
                Formulaire Playground
              </NavLink>
            </li>
            <li>
              <NavLink to="/app/createEventPage">Formulaire Event</NavLink>
            </li>
            <li>
              <NavLink to="/app/createSocietyPage">Formulaire Société</NavLink>
            </li>
            <li>
              <NavLink to="/app/createPartnerPage">
                Formulaire Partenaire
              </NavLink>
            </li>
            <li>
              <NavLink to="/app/createSponsorPage">Formulaire Sponsor</NavLink>
            </li>
            <li>
              <NavLink to="/app/createTeamPage">Formulaire Team</NavLink>
            </li>
            <li>
              <NavLink to="/app/createArticlePage">Formulaire Article</NavLink>
            </li>
          </ul>
          <p className="menu-label">Action</p>
          <ul className="menu-list">
            <li>
              <NavLink
                to="/"
                onClick={(event) => {
                  event.preventDefault();
                  logout(() => navigate(`/app/login`));
                }}
              >
                Déconnexion
              </NavLink>
            </li>
          </ul>
        </aside>
      ) : null}
    </div>
  );
};

export default SideBar;
