import React, { useState, useContext } from "react";
import "../../assets/style/globalStyle.scss";
import {
  addPlayground,
  getPlaygroundById,
} from "../../services/playgroundService";

import { navigate } from "gatsby";
import { GlobalDispatchContext } from "../../context/GlobalContextProvider";

import { Formik } from "formik";
import * as Yup from "yup";

const CreatePlaygroundSchema = Yup.object().shape({
  playgroundName: Yup.string().required("Veuillez entrer le nom du terrain"),
  numberGround: Yup.string()
    .matches(/^[0-9]+$/, "Caractères invalides")
    .required("Veuillez entrer le nombre de terrains"),
  numberHoops: Yup.string()
    .matches(/^[0-9]+$/, "Caractères invalides")
    .required("Veuillez entrer le nombre de paniers"),
  boardType: Yup.string().required("Veuillez choisir le type de panier"),
  netType: Yup.string().required("Veuillez choisir le type de filet"),
  floor: Yup.string().required("Veuillez choisir le type de sol"),
  streetNumber: Yup.string().when("isGPS", {
    is: false,
    then: Yup.string().required("Veuillez entrer le numéro de rue"),
  }),
  streetName: Yup.string().when("isGPS", {
    is: false,
    then: Yup.string().required("Veuillez entrer le nom de rue"),
  }),
  city: Yup.string().when("isGPS", {
    is: false,
    then: Yup.string().required("Veuillez entrer le nom de la ville"),
  }),
  longitude: Yup.string().when("isGPS", {
    is: true,
    then: Yup.string().required("Veuillez entrer une longitude"),
  }),
  latitude: Yup.string().when("isGPS", {
    is: true,
    then: Yup.string().required("Veuillez entrer une latitude"),
  }),
});

const CreatePlaygroundPage = () => {
  const dispatch = useContext(GlobalDispatchContext);

  const [errorMessage, setErrorMessage] = useState("");

  const GoToPlayground = async (id) => {
    const playground = await getPlaygroundById(id);
    dispatch({
      type: "load_playground",
      payload: playground.playground,
      author: playground.authorEmail,
    });
    navigate("/app/updatePlaygroundPage");
  };

  return (
    <div className="box">
      <h3 className="title mt-3">CRÉER UN TERRAIN</h3>
      <h4 className="subtitle">
        Formulaire de création d'un terrain pour un admin BNC
      </h4>
      <p>* Champs obligatoires</p>

      <br />
      <Formik
        initialValues={{
          playgroundName: "",
          numberGround: "",
          numberHoops: "",
          boardType: "",
          netType: "",
          floor: "",
          streetNumber: "",
          streetName: "",
          city: "",
          longitude: "",
          latitude: "",
          water: false,
          note: "",
          isGPS: false,
        }}
        validationSchema={CreatePlaygroundSchema}
        onSubmit={async (values) => {
          if (values.isGPS) {
            try {
              setErrorMessage("");
              const newPlayground = await addPlayground({
                playgroundName: values.playgroundName,
                numberGround: values.numberGround,
                numberHoops: values.numberHoops,
                boardType: values.boardType,
                netType: values.netType,
                floor: values.floor,
                streetNumber: "",
                streetName: "",
                city: "",
                longitude: values.longitude,
                latitude: values.latitude,
                water: values.water,
                note: values.note,
              });
              GoToPlayground(newPlayground._id);
            } catch (error) {
              setErrorMessage(
                "Erreur lors de la création, une adresse introuvable ou un champ incomplet"
              );
            }
          } else {
            try {
              setErrorMessage("");
              const newPlayground = await addPlayground({
                playgroundName: values.playgroundName,
                numberGround: values.numberGround,
                numberHoops: values.numberHoops,
                boardType: values.boardType,
                netType: values.netType,
                floor: values.floor,
                streetNumber: values.streetNumber,
                streetName: values.streetName,
                city: values.city,
                longitude: "",
                latitude: "",
                water: values.water,
                note: values.note,
              });
              GoToPlayground(newPlayground._id);
            } catch (error) {
              setErrorMessage(
                "Erreur lors de la création, une adresse introuvable ou un champ incomplet"
              );
            }
          }
        }}
      >
        {({
          setFieldValue,
          handleChange,
          handleSubmit,
          values,
          errors,
          touched,
        }) => (
          <>
            <div className="columns">
              <div className="column">
                <div className="field">
                  <label className="label">
                    Nom du terrain *
                    <input
                      className="input"
                      type="text"
                      placeholder="Nom de l'évènement"
                      value={values.playgroundName}
                      onChange={(e) =>
                        setFieldValue("playgroundName", e.target.value)
                      }
                    />
                  </label>
                </div>
                {errors.playgroundName && touched.playgroundName && (
                  <p style={{ color: "#cc0000" }}>{errors.playgroundName}</p>
                )}
              </div>
              <div className="column">
                <div className="field">
                  <label className="label">
                    Nombre de terrains *
                    <input
                      className="input"
                      type="numeric"
                      placeholder="Nombre de terrain"
                      value={values.numberGround}
                      onChange={(e) =>
                        setFieldValue("numberGround", e.target.value)
                      }
                    />
                  </label>
                </div>
                {errors.numberGround && touched.numberGround && (
                  <p style={{ color: "#cc0000" }}>{errors.numberGround}</p>
                )}
              </div>
              <div className="column">
                <div className="field">
                  <label className="label">
                    Nombre de paniers *
                    <input
                      className="input"
                      type="numeric"
                      placeholder="Nombre de paniers"
                      value={values.numberHoops}
                      onChange={(e) =>
                        setFieldValue("numberHoops", e.target.value)
                      }
                    />
                  </label>
                </div>
                {errors.numberHoops && touched.numberHoops && (
                  <p style={{ color: "#cc0000" }}>{errors.numberHoops}</p>
                )}
              </div>
            </div>
            <br />

            <p className="label">Point d'eau</p>
            <div className="control columns">
              <div className="column">
                <label className="checkbox">
                  <input
                    type="checkbox"
                    checked={values.water === true}
                    onChange={() => setFieldValue("water", true)}
                  />
                  <span style={{ marginLeft: "8px" }}>Oui</span>
                </label>
              </div>
              <div className="column">
                <label className="checkbox">
                  <input
                    type="checkbox"
                    checked={values.water === false}
                    onChange={() => setFieldValue("water", false)}
                  />
                  <span style={{ marginLeft: "8px" }}>Non</span>
                </label>
              </div>
            </div>

            <hr />

            <p className="label">Position par coordonnées GPS</p>
            <div className="control columns">
              <div className="column">
                <label className="checkbox">
                  <input
                    type="checkbox"
                    checked={values.isGPS === true}
                    onChange={() => setFieldValue("isGPS", true)}
                  />
                  <span style={{ marginLeft: "8px" }}>Oui</span>
                </label>
              </div>
              <div className="column">
                <label className="checkbox">
                  <input
                    type="checkbox"
                    checked={values.isGPS === false}
                    onChange={() => setFieldValue("isGPS", false)}
                  />
                  <span style={{ marginLeft: "8px" }}>Non</span>
                </label>
              </div>
            </div>

            {!values.isGPS ? (
              <div className="columns is-centered">
                <div className="column is-one-quarter">
                  <label className="label">
                    N° de Rue *
                    <input
                      className="input"
                      type="numeric"
                      placeholder="N° de rue"
                      value={values.streetNumber}
                      onChange={(e) =>
                        setFieldValue("streetNumber", e.target.value)
                      }
                    />
                  </label>
                  {errors.streetNumber && touched.streetNumber && (
                    <p style={{ color: "#cc0000" }}>{errors.streetNumber}</p>
                  )}
                </div>

                <div className="column">
                  <label className="label">
                    Nom de la rue *
                    <input
                      className="input"
                      type="text"
                      placeholder="Nom de la rue"
                      value={values.streetName}
                      onChange={(e) =>
                        setFieldValue("streetName", e.target.value)
                      }
                    />
                  </label>
                  {errors.streetName && touched.streetName && (
                    <p style={{ color: "#cc0000" }}>{errors.streetName}</p>
                  )}
                </div>

                <div className="column is-one-quarter">
                  <label className="label">
                    Ville *
                    <input
                      className="input"
                      type="text"
                      placeholder="Nom de la ville"
                      value={values.city}
                      onChange={(e) => setFieldValue("city", e.target.value)}
                    />
                  </label>
                  {errors.city && touched.city && (
                    <p style={{ color: "#cc0000" }}>{errors.city}</p>
                  )}
                </div>
              </div>
            ) : (
              <div className="columns is-centered">
                <div className="column">
                  <label className="label">
                    Longitude *
                    <input
                      className="input"
                      type="numeric"
                      placeholder="Longitude"
                      value={values.longitude}
                      onChange={(e) =>
                        setFieldValue("longitude", e.target.value)
                      }
                    />
                  </label>
                  {errors.longitude && touched.longitude && (
                    <p style={{ color: "#cc0000" }}>{errors.longitude}</p>
                  )}
                </div>

                <div className="column">
                  <label className="label">
                    Latitude *
                    <input
                      className="input"
                      type="numeric"
                      placeholder="Latitude"
                      value={values.latitude}
                      onChange={(e) =>
                        setFieldValue("latitude", e.target.value)
                      }
                    />
                  </label>
                  {errors.latitude && touched.latitude && (
                    <p style={{ color: "#cc0000" }}>{errors.latitude}</p>
                  )}
                </div>
              </div>
            )}
            <hr />
            <div className="field">
              <p className="label">Type de panier *</p>
              <div className="control columns">
                <div className="column">
                  <label className="checkbox">
                    <input
                      type="checkbox"
                      checked={values.boardType === "Plexi"}
                      onChange={() => setFieldValue("boardType", "Plexi")}
                    />
                    <span style={{ marginLeft: "8px" }}>Plexi</span>
                  </label>
                </div>
                <div className="column">
                  <label className="checkbox">
                    <input
                      type="checkbox"
                      checked={values.boardType === "Fer"}
                      onChange={() => setFieldValue("boardType", "Fer")}
                    />
                    <span style={{ marginLeft: "8px" }}>Acier</span>
                  </label>
                </div>
                <div className="column">
                  <label className="checkbox">
                    <input
                      type="checkbox"
                      checked={values.boardType === "Bois"}
                      onChange={() => setFieldValue("boardType", "Bois")}
                    />
                    <span style={{ marginLeft: "8px" }}>Bois</span>
                  </label>
                </div>
                <div className="column">
                  <label className="checkbox">
                    <input
                      type="checkbox"
                      checked={values.boardType === "Plastique"}
                      onChange={() => setFieldValue("boardType", "Plastique")}
                    />
                    <span style={{ marginLeft: "8px" }}>Plastique</span>
                  </label>
                </div>
                {errors.boardType && touched.boardType && (
                  <p style={{ color: "#cc0000" }}>{errors.boardType}</p>
                )}
              </div>
            </div>
            <br />
            <div className="field">
              <p className="label">Type de filet *</p>
              <div className="control columns">
                <div className="column">
                  <label className="checkbox">
                    <input
                      type="checkbox"
                      checked={values.netType === "Chaine"}
                      onChange={() => setFieldValue("netType", "Chaine")}
                    />
                    <span style={{ marginLeft: "8px" }}>Chaîne</span>
                  </label>
                </div>
                <div className="column">
                  <label className="checkbox">
                    <input
                      type="checkbox"
                      checked={values.netType === "Rien"}
                      onChange={() => setFieldValue("netType", "Rien")}
                    />
                    <span style={{ marginLeft: "8px" }}>Pas de filet</span>
                  </label>
                </div>
                <div className="column">
                  <label className="checkbox">
                    <input
                      type="checkbox"
                      checked={values.netType === "Nylon"}
                      onChange={() => setFieldValue("netType", "Nylon")}
                    />
                    <span style={{ marginLeft: "8px" }}>Nylon</span>
                  </label>
                </div>
                <div className="column">
                  <label className="checkbox">
                    <input
                      type="checkbox"
                      checked={values.netType === "Caoutchouc"}
                      onChange={() => setFieldValue("netType", "Caoutchouc")}
                    />
                    <span style={{ marginLeft: "8px" }}>Caoutchouc</span>
                  </label>
                </div>
                {errors.netType && touched.netType && (
                  <p style={{ color: "#cc0000" }}>{errors.netType}</p>
                )}
              </div>
            </div>
            <br />

            <div className="field">
              <p className="label">Type de sol *</p>
              <div className="control columns">
                <div className="column">
                  <label className="checkbox">
                    <input
                      type="checkbox"
                      checked={values.floor === "Béton"}
                      onChange={() => setFieldValue("floor", "Béton")}
                    />
                    <span style={{ marginLeft: "8px" }}>Bitume</span>
                  </label>
                </div>
                <div className="column">
                  <label className="checkbox">
                    <input
                      type="checkbox"
                      checked={values.floor === "Balatum"}
                      onChange={() => setFieldValue("floor", "Balatum")}
                    />
                    <span style={{ marginLeft: "8px" }}>Balatum</span>
                  </label>
                </div>
                <div className="column">
                  <label className="checkbox">
                    <input
                      type="checkbox"
                      checked={values.floor === "Parquet"}
                      onChange={() => setFieldValue("floor", "Parquet")}
                    />
                    <span style={{ marginLeft: "8px" }}>Parquet</span>
                  </label>
                </div>
                <div className="column">
                  <label className="checkbox">
                    <input
                      type="checkbox"
                      checked={values.floor === "Synthétique"}
                      onChange={() => setFieldValue("floor", "Synthétique")}
                    />
                    <span style={{ marginLeft: "8px" }}>Synthétique</span>
                  </label>
                </div>
                {errors.floor && touched.floor && (
                  <p style={{ color: "#cc0000" }}>{errors.floor}</p>
                )}
              </div>
            </div>
            <br />

            <div className="field">
              <label className="label">
                Description
                <textarea
                  className="textarea"
                  placeholder="Description"
                  value={values.note}
                  onChange={(e) => setFieldValue("note", e.target.value)}
                ></textarea>
              </label>
            </div>
            <br />
            {errorMessage !== "" && (
              <>
                <p>{errorMessage}</p>
                <br />
              </>
            )}

            <div className="columns is-multiline">
              <div className="column">
                <button
                  className="button is-success is-outlined"
                  type="submit"
                  onClick={async () => handleSubmit()}
                >
                  Créer terrain
                </button>
              </div>
            </div>
          </>
        )}
      </Formik>
    </div>
  );
};

export default CreatePlaygroundPage;
