import React, { useState, useEffect, useContext } from "react";
import "../../assets/style/globalStyle.scss";
import { navigate } from "gatsby";
import { getAllEvents, getEventById } from "../../services/eventService";
import { GlobalDispatchContext } from "../../context/GlobalContextProvider";

const AllEvents = () => {
  const dispatch = useContext(GlobalDispatchContext);
  const [events, setEvents] = useState([]);

  const getAllEventsToModerate = async () => {
    const data = await getAllEvents();

    if (!data) {
      return (
        <div className="container is-fullhd" style={{ width: "880px" }}>
          <h1>Pas d'évènements en cours</h1>
        </div>
      );
    }

    setEvents(data);
  };

  const GoToEvent = async (id) => {
    const event = await getEventById(id);
    dispatch({
      type: "get_event",
      payload: event,
    });
    navigate("/app/updateEventPage");
  };

  useEffect(() => {
    getAllEventsToModerate();
  }, []);

  return (
    <div className="box">
      <table className="table is-striped is-hoverable is-responsive">
        <thead>
          <tr>
            <th style={{ width: "200px" }}>
              <abbr title="Event-name">Nom de l'évènement</abbr>
            </th>
            <th className="is-hidden-mobile">
              <abbr title="Event-type">Type</abbr>
            </th>
            <th className="is-hidden-mobile">
              <abbr title="StartDate">Date de début</abbr>
            </th>
            <th className="is-hidden-mobile">
              <abbr title="EndDate">Date de fin</abbr>
            </th>
            <th style={{ width: "200px" }}>
              <abbr title="City">Ville</abbr>
            </th>

            <th className="is-hidden-mobile">
              <abbr title="Zip code">Code postal</abbr>
            </th>
          </tr>
        </thead>

        <tbody>
          {events.length !== 0 &&
            events.events.map((event) => (
              <tr key={event.name} style={{ height: "45px" }}>
                <td>{event.name}</td>
                <td className="is-hidden-mobile">{event.type}</td>
                <td className="is-hidden-mobile">
                  {new Intl.DateTimeFormat("fr-FR").format(
                    Date.parse(event.startDate)
                  )}
                </td>
                <td className="is-hidden-mobile">
                  {new Intl.DateTimeFormat("fr-FR").format(
                    Date.parse(event.endDate)
                  )}
                </td>
                <td>{event.city}</td>
                <td className="is-hidden-mobile">{event.zipCode}</td>
                <td>
                  <button
                    className="button is-primary"
                    onClick={() => {
                      GoToEvent(event._id);
                    }}
                  >
                    <span className="icon is-medium">
                      <span className="fa-stack">
                        <i className="fas fa-circle fa-stack-2x"></i>
                      </span>
                    </span>
                  </button>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default AllEvents;
