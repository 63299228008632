import React, { useContext } from "react";
import "../../assets/style/globalStyle.scss";
import { addArticle, getArticleById } from "../../services/articleService";
import { GlobalDispatchContext } from "../../context/GlobalContextProvider";
import { navigate } from "gatsby";

import { Formik } from "formik";
import * as Yup from "yup";

const CreateArticleSchema = Yup.object().shape({
  title: Yup.string().required("Veuillez entrer le titre de votre article"),
  author: Yup.string().required("Veuillez entrer l'autheur de votre article"),
  date: Yup.string().required("Veuillez choisir une date d'article"),
  description: Yup.string().required("Veuillez entrer la description de l'article"),
  categorie: Yup.string().required("Veuillez choisir une catégorie"),
  link: Yup.string().required("Veuillez entrer le lien de votre article"),
});

const CreateArticlePage = () => {
  const dispatch = useContext(GlobalDispatchContext);

  const GoToEvent = async (id) => {
    const article = await getArticleById(id);
    dispatch({
      type: "get_article",
      payload: article,
    });
    navigate("/app/updateArticlePage");
  };

  return (
    <div className="box">
      <h3 className="title mt-3">AJOUTER UN ARTICLE</h3>
      <h4 className="subtitle">
        Formulaire d'ajout d'un article pour un admin BNC
      </h4>
      <p>* Champ obligatoire</p>
      <br />
      <Formik
        initialValues={{
          title: "",
          author: "",
          date: "",
          description: "",
          categorie: "",
          link: "",
        }}
        validationSchema={CreateArticleSchema}
        onSubmit={async (values) => {
          let newArticle;

          newArticle = await addArticle({
            title: values.title,
            author: values.author,
            date: values.date,
            description: values.description,
            image: "",
            categorie: values.categorie,
            link: values.link,
          });

          dispatch({
            type: "get_article",
            payload: newArticle,
          });
          GoToEvent(newArticle._id);
        }}
      >
        {({
          setFieldValue,
          handleSubmit,
          values,
          errors,
          touched,
        }) => (
          <>
            <div className="columns">
              <div className="column">
                <label className="label">
                  Titre de l'article *
                  <input
                    className="input"
                    type="text"
                    placeholder="Titre de l'article"
                    value={values.title}
                    required={true}
                    onChange={(e) => {
                      setFieldValue("title", e.target.value);
                    }}
                  />
                </label>
                {errors.title && touched.title && (
                  <p style={{ color: "#cc0000" }}>{errors.title}</p>
                )}
              </div>
              <div className="column">
                <label className="label">
                  Auteur de l'article *
                  <input
                    className="input"
                    type="text"
                    placeholder="Titre de l'article"
                    value={values.author}
                    required={true}
                    onChange={(e) => {
                      setFieldValue("author", e.target.value);
                    }}
                  />
                </label>
                {errors.author && touched.author && (
                  <p style={{ color: "#cc0000" }}>{errors.author}</p>
                )}
              </div>
              <div className="column">
                <label className="label">
                  Date d'ajout *
                  <input
                    className="input"
                    type="date"
                    value={values.date}
                    required={true}
                    onChange={(e) => setFieldValue("date", e.target.value)}
                  />
                </label>
                {errors.date && touched.date && (
                  <p style={{ color: "#cc0000" }}>{errors.date}</p>
                )}
              </div>
            </div>
            <div className="columns">
              <div className="column">
                <div className="field">
                  <label className="label">
                    Lien de l'article *
                    <input
                      className="input"
                      type="text"
                      placeholder="Lien de l'article"
                      value={values.link}
                      onChange={(e) => setFieldValue("link", e.target.value)}
                    />
                  </label>
                </div>
                {errors.link && touched.link && (
                  <p style={{ color: "#cc0000" }}>{errors.link}</p>
                )}
              </div>
              <div className="column">
                <label className="label">
                  Catégorie de l'article *
                  <br />
                  <div
                    className="select"
                    value={values.categorie}
                    onChange={(e) => setFieldValue("categorie", e.target.value)}
                  >
                    <select>
                      <option>Sélectionner votre catégorie d'article</option>
                      <option value="Equipe">Equipe</option>
                      <option value="Coach">Coach</option>
                      <option value="Application">Application</option>
                      <option value="NBA">NBA</option>
                      <option value="BallNConnect">Ball'N Connect</option>
                      <option value="Evenement">Evenement</option>
                    </select>
                  </div>
                </label>
                {errors.categorie && touched.categorie && (
                  <p style={{ color: "#cc0000" }}>{errors.categorie}</p>
                )}
              </div>
            </div>
            <div className="field">
              <label className="label">
                Description *
                <textarea
                  className="textarea"
                  placeholder="Description de l'événement"
                  value={values.description}
                  onChange={(e) => setFieldValue("description", e.target.value)}
                ></textarea>
              </label>
              {errors.description && touched.description && (
                <p style={{ color: "#cc0000" }}>{errors.description}</p>
              )}
            </div>
            <br />

            <div className="columns is-multiline">
              <div className="column">
                <button
                  className="button is-primary is-outlined"
                  type="submit"
                  onClick={() => {
                    handleSubmit();
                  }}
                >
                  Ajout Article
                </button>
              </div>
            </div>
          </>
        )}
      </Formik>
    </div>
  );
};

export default CreateArticlePage;
