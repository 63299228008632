import React from "react";

import NavBar from "./navBar";
import SideBar from "./sideBar";
import "../assets/style/globalStyle.scss";

const Layout = ({ children }) => (
  <div
    className="pt-6"
    style={{
      background:
        "linear-gradient(90deg, rgba(242,99,54,1) 0%, rgba(138,80,139,1) 100%",
    }}
  >
    <div className="columns is-12">
      <NavBar />
      <SideBar />
      <div className="column is-10 mt-4 mobile is-hidden-desktop">{children}</div>
      <div className="column is-10-desktop mt-4 p-6 is-hidden-mobile">{children}</div>
    </div>
  </div>
);

export default Layout;
