import React, { useState, useEffect, useContext } from "react";
import "../../assets/style/globalStyle.scss";
import { navigate } from "gatsby";
import { getAllPartners, getPartnerById } from "../../services/partnerService";

import CardSociety from "../../components/cardSociety";
import { GlobalDispatchContext } from "../../context/GlobalContextProvider";

const AllPartners = () => {
  const dispatch = useContext(GlobalDispatchContext);
  const [partners, setPartners] = useState([]);
  const [noPartner, setnoPartner] = useState(false);

  const getAllPartnersToDisplay = async () => {
    const data = await getAllPartners();

    if (!data) {
      return setnoPartner(true);
    }

    setPartners(data);
  };

  const GoToPartner = async (id) => {
    const partner = await getPartnerById(id);
    dispatch({
      type: "get_partner",
      payload: partner,
    });
    navigate("/app/updatePartnerPage");
  };

  useEffect(() => {
    getAllPartnersToDisplay();
  }, []);

  if (noPartner || partners.length === 0) {
    return (
      <div className="container is-fullhd" style={{ width: "880px" }}>
        <h1>Pas de partenaire enregistrée</h1>
      </div>
    );
  }

  return (
    <div className="columns is-multiline">
      {partners.length !== 0 &&
        partners.partner.map((partner) => (
          <div key={partner.name} className="column is-one-third">
            <CardSociety
              logo={
                partner.logo
                  ? `https://res.cloudinary.com/ball-n-connect/image/v1/upload/partner_image/${partner._id}.png`
                  : null
              }
              name={partner.name}
              url={partner.url}
              phoneNumber={partner.phoneNumber}
              email={partner.email}
              contactName={partner.contactName}
              description={partner.description}
              onClick={() => GoToPartner(partner._id)}
            />
          </div>
        ))}
    </div>
  );
};

export default AllPartners;
